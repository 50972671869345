import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./headline.css";
import { makeStyles } from "@material-ui/styles";
import BackgroundImage from "gatsby-background-image";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
// import  background  from "../img/frontimg2.jpg";


const Headline = ({ HeadIcon, header, backgroundImageData}) => {
  const useStyles = makeStyles({
    iconheadStyle: {
      width: "50px",
      height: "50px",
      color: "white",
    },
  });

  const styles = useStyles();
  const image = getImage(backgroundImageData);
  const bgImage = convertToBgImage(image);

  return (
    <BackgroundImage 
      className="headline container-fluid"
      backgroundColor={`#ffffff`}
      {...bgImage}
      preserveStackingContext
      >
      <Container>
      <Row>
        <Col md="2" sm="12 text-center" className="mt-4">
          <Button disabled className="headerball">
            <div>
              <HeadIcon className={styles.iconheadStyle}></HeadIcon>
            </div>
          </Button>
        </Col>

        <Col className="mt-auto text-md-left text-sm-center">
          <h1 md="6">{header}</h1>
          <hr></hr>
        </Col>
      </Row>
      </Container>

    </BackgroundImage>
  );
};

export default Headline;
