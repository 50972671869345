import React from "react";
import Layout from "../layout";
import Headline from "../components/Headline";
import TextBlock from "../components/TextBlock";
import { Add } from "@material-ui/icons";
import { Container, Row, Col } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../components/seo";
//import  background  from "../img/plusimg2.jpg";
//import kuva5  from '../img/katelaskenta.png';

export default function Lisatoiminnot() {
  // Load images
  // Specify sizing, quality etc. here
  const imgdata = useStaticQuery(graphql`{
  bg: file(relativePath: {eq: "plusimg2.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH, quality: 90)
    }
  }
}
`)
  const bgImageData = imgdata.bg;

  return <>
  <SEO title="Lisätoiminnot" description="Lisätoiminnot: Tiedostosivu hakutoiminnolla | Laskutus | Katelaskenta | Raportointi"/>
  <Layout>
    <Headline HeadIcon={Add} header="Lisätoiminnot" backgroundImageData={bgImageData}/>
  <Container className="page_container">
    
    <Row>
      <Col md="4">
        <TextBlock
          Header="Tiedostosivu hakutoiminnolla"
          Paragraph="Sisäinen tiedostopankki aina saatavilla."
        ></TextBlock>
        <TextBlock
          Header="Laskutus"
          Paragraph="Tilauksilla laskutettu tila. Mahdollisuus laskutusrajapintoihin, jolloin laskujen ja asiakastietojen siirto automaattisesti taloushallinnon järjestelmiin."
        ></TextBlock>
        <TextBlock
          Header="Katelaskenta"
          Paragraph="Tarkka ja tehokas katelaskenta perustuen tuoterakenteeseen. Voidaan määrittää myös työ ja muut kulut, jolloin katelaskenta on täsmälleen oikein ja auttaa hinnoittelemaan."
        ></TextBlock>
        <TextBlock
          Header="Raportointi"
          Paragraph="Laajat raportointimahdollisuudet mm. myynnistä, tuotteista, varastosta ja tulevaisuuden tarpeista."
        ></TextBlock>
      </Col>

      <Col md="8">
        <StaticImage src="../../content/images/katelaskenta.png" className="kuva" alt="Katelaskenta" layout="fullWidth"/>
      </Col>
    </Row>
  </Container>
  </Layout>
  </>;
}
