import React from "react";
import { Container, Row } from "react-bootstrap";
import { makeStyles } from "@material-ui/styles";
import "./textblock.css";

const TextBlock = ({ Header, Paragraph }) => {
  const useStyles = makeStyles({
    iconblockStyle: {
      width: "35px",
      height: "35px",
      color: "white",
    },
    headerStyle: {
      color:"#27b1bf"
    }
  });

  const styles = useStyles();

  return (
    <Container>
      {/* <Row>
        <div className="block_header">
          <Icon className={styles.iconblockStyle}></Icon>
        </div>
      </Row> */}

      <Row className="blockheader">
        <h5 className={styles.headerStyle}>{Header}</h5>
      </Row>
      <Row>
        <div>
      <p
            dangerouslySetInnerHTML={{
              __html: Paragraph,
            }}
          ></p></div>
      </Row>


    </Container>
  );
};

export default TextBlock;
